import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core"

const useStyles = makeStyles({
  root: {}
})

export default ({ open, handleClose, handleAddAthlete }) => {
  const [newAthleteNumber, setNewAthleteNumber] = useState("")

  const handleChange = e => {
    const value = event.target.value
    if (value === "") {
      setNewAthleteNumber("")
    } else {
      const intVal = parseInt(value)
      if (!isNaN(intVal)) {
        setNewAthleteNumber(intVal)
      }
    }
  }

  const triggerAdd = () => {
    handleAddAthlete(newAthleteNumber)
    handleClose()
    setNewAthleteNumber("")
  }

  const handleKeyUp = e => {
    if (e.key === "Enter") {
      triggerAdd()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Friend</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter an athlete number to add a friend.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Athlete number"
          fullWidth
          value={newAthleteNumber}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={newAthleteNumber === ""}
          onClick={triggerAdd}
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
