import React, { useLayoutEffect, useState } from "react"
import Social from "./Social"
import queryString from "query-string"
import _map from "lodash/map"
import _filter from "lodash/filter"

const theme = {
  palette: {
    primary1Color: "#ffa300",
    textColor: "#272727"
  }
}

const readAthleteNumbers = () => {
  var parsed = queryString.parse(location.search, {
    arrayFormat: "comma"
  })["athleteNumbers"]
  if (parsed) {
    if (typeof parsed == "string") {
      // singular - wrap it up
      parsed = [parsed]
    }

    // convert to ints
    // careful with args here - can't pass parseInt directly as _map uses second position for index
    return _map(parsed, v => parseInt(v))
  } else {
    return []
  }
}

export default () => {
  const [athleteNumbers, setAthleteNumbers] = useState([])
  useLayoutEffect(() => setAthleteNumbers(readAthleteNumbers()), [])

  const updateAthleteNumbers = newNumbers => {
    const qs = queryString.stringify(
      { athleteNumbers: newNumbers },
      { arrayFormat: "comma" }
    )

    history.pushState({}, document.title, "/?" + qs)
    setAthleteNumbers(newNumbers)
  }

  const handleAddAthlete = newAthleteNumber =>
    updateAthleteNumbers([...athleteNumbers, newAthleteNumber])

  const handleRemoveAthlete = toRemove =>
    updateAthleteNumbers(_filter(athleteNumbers, v => v != toRemove))

  return (
    <Social
      athleteNumbers={athleteNumbers}
      handleAddAthlete={handleAddAthlete}
      handleRemoveAthlete={handleRemoveAthlete}
    />
  )
}
