const baseURL = "https://parkrundata-v3yigeirza-ew.a.run.app/"

export function loadAthlete(athleteNumber) {
  console.log("Loading athlete: A", athleteNumber)
  return new Promise((resolve, reject) => {
    fetch(baseURL + "athlete/" + athleteNumber, {
      method: "GET",
      mode: "cors"
    }).then(response => {
      if (response.ok) {
        resolve(response.json())
      } else {
        console.log("Load failed", response)
        reject(response.status)
      }
    })
  })
    .then(data => {
      // append in the athlete num
      return { ...data, number: athleteNumber }
    })
    .then(athlete => {
      console.log("Loaded athlete: ", athlete)
      return athlete
    })
}
