import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { CircularProgress, IconButton, Box } from "@material-ui/core"
import moment from "moment"
import _reduce from "lodash/reduce"
import _filter from "lodash/filter"
import _join from "lodash/join"
import _negate from "lodash/negate"
import DeleteIcon from "@material-ui/icons/Delete"
import ErrorIcon from "@material-ui/icons/Error"
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser"

const useStyles = makeStyles({
  root: {},
  title: {
    fontSize: 14,
    flexGrow: "1"
  },
  pos: {
    marginBottom: 12
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
})

const SummaryOfRuns = ({ athlete, lastRun, now }) => {
  const classes = useStyles()

  const isJunior = s => s.eventCode.endsWith("-juniors")
  const juniorSummaries = _filter(athlete.eventSummaries, isJunior)
  const seniorSummaries = _filter(athlete.eventSummaries, _negate(isJunior))
  const totalSeniorRuns = _reduce(seniorSummaries, (n, v) => n + v.runs, 0)
  const totalJuniorRuns = _reduce(juniorSummaries, (n, v) => n + v.runs, 0)

  const totalEvents = athlete.eventSummaries.length

  const summaries = []

  if (totalJuniorRuns > 0) {
    summaries.push(totalJuniorRuns + " junior parkruns")
  }
  if (totalSeniorRuns > 0) {
    summaries.push(totalSeniorRuns + " parkruns")
  }
  summaries.push(totalEvents + " events")

  return (
    <>
      <Typography variant="body2" component="p">
        Last run{" "}
        {moment(lastRun.eventDate)
          .hour(9) // parkruns are usually at 9am. Let's assume that for now.
          .from(now)}
        :
      </Typography>
      <Typography className={classes.pos} variant="body2" component="p">
        {lastRun.eventCode} - {lastRun.time}
      </Typography>
      <Typography className={classes.pos} color="textSecondary"></Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        {_join(summaries, ", ")}
      </Typography>
    </>
  )
}

const AthleteCard = ({ athlete, now }) => {
  const lastRun = athlete.recentRuns[0]

  const summary = lastRun ? (
    <SummaryOfRuns lastRun={lastRun} athlete={athlete} now={now} />
  ) : (
    <Typography variant="body2" component="p">
      No runs
    </Typography>
  )

  return (
    <>
      <Typography variant="h5" component="h2">
        {athlete.name}
      </Typography>
      {summary}
    </>
  )
}

export default ({ athleteNumber, athlete, handleRemoveAthlete, now }) => {
  const classes = useStyles()
  var content = undefined

  if (athlete && athlete.error) {
    content = (
      <Typography>
        <ErrorIcon color="secondary" />
        Error
      </Typography>
    )
  } else if (athlete) {
    content = <AthleteCard athlete={athlete} now={now} />
  } else {
    content = <CircularProgress />
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box className={classes.header}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            A{athleteNumber}
          </Typography>
          <IconButton
            size="small"
            onClick={() => handleRemoveAthlete(athleteNumber)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        {content}
      </CardContent>
      <CardActions className={classes.actions}>
        <IconButton
          size="small"
          href={
            "https://www.parkrun.org.uk/results/athleteresultshistory/?athleteNumber=" +
            athleteNumber
          }
        >
          <OpenInBrowserIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}
