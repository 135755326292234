import React, { useReducer, useEffect, useState } from "react"
import {
  Typography,
  AppBar,
  Box,
  makeStyles,
  Toolbar,
  Fab
} from "@material-ui/core"
import FriendCard from "./FriendCard"
import _map from "lodash/map"
import _forEach from "lodash/forEach"
import { loadAthlete } from "./Loader"
import AddIcon from "@material-ui/icons/Add"
import AddFriendDialog from "./AddFriendDialog"
import moment from "moment"

const useStyles = makeStyles({
  box: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 250px)",
    gridGap: "20px",
    margin: "20px"
  },
  fab: {
    position: "fixed",
    right: 50,
    bottom: 50
  }
})

export default ({ athleteNumbers, handleAddAthlete, handleRemoveAthlete }) => {
  const classes = useStyles()
  const [data, dispatch] = useReducer(
    (state, action) => ({ ...state, [action.athleteNumber]: action.athlete }),
    {}
  )

  useEffect(() => {
    _forEach(athleteNumbers, athleteNumber => {
      if (data[athleteNumber]) {
        return // don't load again if we have the data
      }

      loadAthlete(athleteNumber)
        .then(athlete =>
          dispatch({ athleteNumber: athleteNumber, athlete: athlete })
        )
        .catch(reason =>
          dispatch({ athleteNumber: athleteNumber, athlete: { error: reason } })
        )
    })
  }, [athleteNumbers])

  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const now = moment()

  return (
    <>
      <AppBar>
        <Toolbar>
          <Typography variant="h4" component="h4">
            Social Parkrun
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box className={classes.box}>
        {_map(athleteNumbers, athleteNumber => (
          <FriendCard
            athlete={data[athleteNumber]}
            key={athleteNumber}
            athleteNumber={athleteNumber}
            handleRemoveAthlete={handleRemoveAthlete}
            now={now}
          />
        ))}
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => setAddDialogOpen(true)}
        >
          <AddIcon />
        </Fab>
      </Box>
      <AddFriendDialog
        open={addDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
        handleAddAthlete={handleAddAthlete}
      />
    </>
  )
}
